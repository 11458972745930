import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { cardSections, cardEmptySection } from "./compare-product-card.module.css"

export default function ProductSummary ({info}) {
    let curImage = null;
    let renderInfo = null;
    const data = useStaticQuery(graphql`
        query {
            images: allFile(filter: {relativePath: {regex: "/images\/products/"}}) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
                          }
                    }
                }
            }
        }
    `)

    function shortenName(n) {
        if(n.length > 10) {
            return (n.slice(0, 30) + "...");
        } else {
            return n
        }
    }

    if(info && info.compare) {
        // console.log(info.compare);
        const image = data.images.edges.find(n => {
            return n.node.relativePath.includes(info.compare.variation_image)
        })
        if(!image) {
            return null;
        }

        const finalImage = getImage(image.node);

        curImage = <GatsbyImage image={finalImage} alt={"Picture of " + info.product_name} />
        
        renderInfo = (
        <div>
            {curImage && curImage}
            <div className={cardSections}>
                <div>{shortenName(info.product_name)}</div>
                <div>Sku: {info.sku}</div>
                <div>${info.us_price} USD</div>
                {info.compare.number_tests > 0 ? <div>{info.compare.number_tests + " " + info.compare.cust1}</div> : <div>N/A</div>}
                {info.compare.kit_size && <div>{info.compare.kit_size}</div>}
                {info.compare.buffer_type !== "N/A" ? <div>{info.compare.buffer_type} Buffer</div> : <div>{info.compare.buffer_type}</div>}
                {info.compare.cust2 && <div>{info.compare.cust2}</div>}
                {info.compare.cust3 && <div>{info.compare.cust3}</div>}
                {info.compare.cust4 && <div>{info.compare.cust4}</div>}
                {info.compare.cust5 && <div>{info.compare.cust5}</div>}
                {info.compare.cust6 && <div>{info.compare.cust6}</div>}
                {info.compare.cust7 && <div>{info.compare.cust7}</div>}
            </div>
        </div>
        )
    } else {
        renderInfo = <div className={cardEmptySection}>select a product to compare</div>
    }

    // const image = getImage(info.variation_image)

    return (
        <>
           {renderInfo}
        </>
    )
}
