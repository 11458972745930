import React, {useState} from 'react'
import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import ProductSummary from '../components/compare-product-card'

function SingleProduct ({product}) {
    const [filterProduct, setFilterProduct] = useState({value: "", product: ""})

    function handleSubmit(e) {
        e.preventDefault();
    }
   
    function handleChange(e) {
        let curSelection = e.target.value;
        let selectedProduct = newProduct.filter(({sku})=> sku === curSelection);
        // console.log("selected product: ", selectedProduct);
        setFilterProduct({value: curSelection, product: selectedProduct[0]})
    }

    function handleBlur(e) {
        e.preventDefault();
    }

    let renderedOptions = null;
    let newProduct = null;

    function shortenName(n) {
        if(n.length > 10) {
            return (n.slice(0, 20) + "...");
        } else {
            return n
        }
    }

    if(product) {
        newProduct = [...product]
        renderedOptions = newProduct.map(({sku, product_name}, index) => (<option key={index} value={sku}>{sku} - {shortenName(product_name)}</option>));
    }

    return (
        <div className="singleProduct">
            <form onSubmit={handleSubmit}>
                <div className="control">
                    <div className={["select", "is-primary"].join(" ")}>
                    <select value={filterProduct.value} onBlur={handleBlur} onChange={handleChange}>
                    <option value="">Select Kit</option>
                    {product && renderedOptions}
                    </select>
                    </div>
                </div>
            </form>
            <ProductSummary info={filterProduct.product}/>
        </div>
    )
}
 
export default function CompareKits({data}) {
    const pageTitle = "Compare RSID Kits";
    
    const pageDescription = "Compare kits page. Independent Forensics is a unique DNA research and analysis laboratory with a powerful record of breaking through barriers in commercial science. Our experienced team has over 60 combined years experience in the DNA and related scientific fields."

    const [filter, setFilter] = useState({id: "", variations: ""});

    let allProducts = data.products.edges;

    function sortVariations(v) {
        let curProductGroup = [];
        let curVariations = [];
        curProductGroup = allProducts.filter(({node}) => node.group === v);
        curProductGroup.forEach(({node}) => curVariations = [...curVariations, ...node.variations]);
        return curVariations;
    }

    function filterSelectedProducts (p) {
      
        if(p === "") {
            return [];
        } else if(p ==="rsid" ){
            return sortVariations(p);
        } else if(p ==="hyliter" ){
            return sortVariations(p);
        } else if(p ==="onetouch" ){
            return sortVariations(p);
        } else if(p ==="amplicon" ){
            return sortVariations(p);
        } else if(p ==="iplex" ){
            return sortVariations(p);
        } else {
            return [];
        }
    }
  
    function handleChange(e) {
        let curVar = filterSelectedProducts(e.target.value);
        setFilter({id: e.target.value, variations: curVar});
    }

    function handleSubmit(e) {
        e.preventDefault();
    }

    function handleBlur(e) {
        e.preventDefault();
    }

    return (
        <Layout>
            <SEO title={pageTitle} description={pageDescription} />
            <Header headerText="Compare kits" />
            <div><p>Ways to order: <Link to="/shop/">Online Product List</Link>, <a href="https://store.ifi-test.com/" rel="noreferrer noopener">Shopify Order Form</a>, <Link to="/order-form/">Editable PDF Price List</Link>.</p></div>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <div className="control">
                        <div className={["select", "is-primary"].join(" ")}>
                            <select value={filter.id} onChange={handleChange} onBlur={handleBlur}>
                            <option value="">Filter by</option>
                            <option value="rsid">RSID</option>
                            {/* <option value="hyliter">HYLITER</option>
                            <option value="onetouch">ONETOUCH</option>
                            <option value="amplicon">AMPLICON RX</option>
                            <option value="iplex">IPLEX</option> */}
                            </select>
                        </div>
                    </div>
                </div>
            </form>
            <div className="singleProductContainer">
                <SingleProduct key={filter.id + 1} product={filter.variations} />
                <SingleProduct key={filter.id + 2} product={filter.variations} />
                <SingleProduct key={filter.id + 3} product={filter.variations} />
            </div>
        </Layout>
    )
}

export const query = graphql `
    query {
        products: allProductsJson {
            edges {
                node {
                    group
                    variations {
                        sku
                        product_name
                        excerpt
                        us_price
                        compare {
                            variation_image
                            number_tests
                            kit_size
                            number_tests
                            buffer_type
                            cust1
                            cust2
                            cust3
                            cust4
                            cust5
                            cust6
                            cust7
                        }
                    }
                }
            }
        }
    }
`